// All direct requests to the API from the frontend are made here.
import axios from 'axios';
import { mapActions } from 'vuex';
import camelize from 'camelize';
import snakeize from 'snakeize';
import { apiUrl } from '../env';

function getOptions(token) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}


export const api = {
    async actionLogin(payload) {
      const response = await axios.post(
        `${apiUrl}/login/access-token`, snakeize(payload)
      )
      return camelize(response.data)
    },

    async getMembership(token, eventId = null, userId = null) {
      let queryParams = [
        (eventId != null) ? `event_id=${eventId}` : '',
        (userId != null) ? `user_id=${userId}` : '',
      ]
      let queryStr = queryParams.join('&')

      const response = await axios.get(
        `${apiUrl}/membership?${queryStr}`, getOptions(token)
      )
      return camelize(response.data)
    },

    async getUsers(token) {
      const response = await axios.get(
        `${apiUrl}/users`, getOptions(token)
      )
      return camelize(response.data)
    },

    async createUser(token, name, password) {
      const payload = {name: name, password: password}
      const response = await axios.post(
        `${apiUrl}/users`, snakeize(payload), getOptions(token)
      )
      return camelize(response.data)
    },

    async joinEvent(token, joinCode) {
      const payload = {joinCode: joinCode}
      const response = await axios.post(
        `${apiUrl}/events/join`, snakeize(payload), getOptions(token)
      )
      return camelize(response.data)
    },

    async createEvent(token, name) {
      const payload = {name: name}
      const response = await axios.post(
        `${apiUrl}/events`, snakeize(payload), getOptions(token)
      )
      return camelize(response.data)
    },

    async getMarketsByEventId(token, eventId) {
      const response = await axios.get(
        `${apiUrl}/markets?event_id=${eventId}`, getOptions(token)
      )
      return camelize(response.data)
    },

    async createMarket(token, eventId, name, selections) {
      const payload = {eventId: eventId, name: name, selections: selections}
      const response = await axios.post(
        `${apiUrl}/markets`, snakeize(payload), getOptions(token)
      )
      return camelize(response.data)
    },

    async createBet(token, selectionId, price, stake, marketId, eventId) {
      const payload = {
        selectionId: selectionId,
        price: price,
        stake: stake,
        marketId: marketId,
        eventId: eventId
      }
      const response = await axios.post(
        `${apiUrl}/bets`, snakeize(payload), getOptions(token)
      )
      return camelize(response.data)
    },

    async getBets(token, eventId) {
      const response = await axios.get(
        `${apiUrl}/bets?event_id=${eventId}`, getOptions(token)
      )
      return camelize(response.data)
    },

    async updateMarketStatus(token, marketId, newStatus) {
      const payload = {
        marketId: marketId,
        status: newStatus
      }
      const response = await axios.post(
        `${apiUrl}/markets/status`, snakeize(payload), getOptions(token)
      )
      return camelize(response.data)
    },

    async settlement(token, marketId, winningSelectionId) {
      const payload = {
        marketId: marketId,
        winningSelectionId: winningSelectionId
      }
      const response = await axios.post(
        `${apiUrl}/settlement`, snakeize(payload), getOptions(token)
      )
      return camelize(response.data)
    },

    async getLeaderboard(token, eventId) {
      const response = await axios.get(
        `${apiUrl}/leaderboard?event_id=${eventId}`, getOptions(token)
      )
      return camelize(response.data)
    }

}