<template>
    <div class="card | flow-1">
        <div class="card-header">
            <div class="card-title">
                <h3 class="fw-semi-bold fs-subheader">{{ data.event.name }}</h3>
            </div>
            <router-link class="event-link" :to="{ name: 'Event', params: { id: data.eventId }}">
                <img class="icon icon-regular" src="../assets/right-light.png" alt="Go">
            </router-link>
        </div>
        <div class="card-footer | flow-1">
            <h3>Join Code: <span class="fw-semi-bold">{{ data.event.joinCode }}</span></h3>
            <h3>Balance: <span class="fw-semi-bold">{{ data.balance.toFixed(2) }}</span></h3>
        </div>
    </div>
</template>

<script>

export default {
    name: 'MembershipCard',
    props: {
        data: Object
    }
}

</script>

<style scoped>

.card {
    border-radius: 10px;
    overflow: hidden;
}

.card-header {
    display: flex;
    justify-content: space-between;
    gap: 0.25rem;
}

.card-title {
    background-color: var(--clr-neutral-extradark);
    color: white;
    flex: 1;
    padding: 0.5rem;
}

.event-link {
    background-color: var(--clr-neutral-dark);
    padding: 0.5rem;
    display: flex;
    align-items: center;
    width: max-content;
    cursor: pointer;
}

a {
    display: flex;
    justify-content: center;
    width: 100%;
    cursor: pointer;
}

.card-footer > * {
    background-color: var(--clr-neutral-light);
    padding: 0.25rem 0.5rem;
}

@media (min-width: 50em) {
    .card {
        width: max(40%, 30em);
    }

}


</style>