<template>
    <div class="tab | flow-3">
        <div class="back-markets | flow-3">
            <MarketCard
                v-for="(market, index) in backMarkets" 
                :key="market.id" 
                :index="index" 
                :data="market" 
                :isCreator="false"
                @bet-placed="$emit('bet-placed')" />
        </div>
        <div class="lay-markets | flow-3">
            <MarketCard 
                v-for="(market, index) in layMarkets" 
                :key="market.id" 
                :index="index" 
                :data="market" 
                :isCreator="true" 
                @market-status-change="$emit('market-status-change')"
                @market-settled="$emit('market-settled')" />
            <CreateMarketCard
                :eventId="this.eventId" 
                @market-status-change="$emit('market-status-change')" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import CreateMarketCard from './CreateMarketCard.vue';
import MarketCard from './MarketCard.vue';

export default {
    name: "EventMarketsTab",
    components: { MarketCard, CreateMarketCard },
    props: { 
        markets: Object,
        eventId: Number
    },
    computed: {
        ...mapState(['user']),
        backMarkets() {
            return (
                this.markets
                .filter((m) => m.creatorId != this.user.id)
                .filter((m) => m.status == 'open')
            )
        },
        layMarkets() {
            return (
                this.markets
                .filter((m) => m.creatorId == this.user.id)
                .sort((a, b) => a.id - b.id)
            )
        }
    },
    emits: [ 'bet-placed', 'market-status-change', 'market-settled' ]
}

</script>

<style scoped>

@media (min-width: 50em) {

    .tab {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }

    .back-markets {
        grid-column: 1;
    }

    .lay-markets {
        margin-top: 0;
        grid-column: 2;
    }

}

</style>

