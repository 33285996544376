<template>
    <div class="card | flow-1">
        <div class="card-header">
            <div class="card-title fs-subheader" :class="{'card-title-creator': isCreator}">
                <h1 class="fw-semi-bold">{{ data.name }}</h1>
            </div>
            <div class="card-toggle" :class="{'card-toggle-creator': isCreator}" @click="toggleExpanded()">
                <img v-if="expanded" class="icon icon-regular" src="../assets/contract-light.png" alt="Contract">
                <img v-else class="icon icon-regular" src="../assets/expand-light.png" alt="Expand">
            </div>    
        </div>
        <div v-show="expanded" class="card-body | flow-1">
            <Selection v-for="(selection, index) in sortedSelections"
                :key="selection.id"
                :index="index"
                :selectionId="selection.id"
                :name="selection.name"
                :price="selection.price"
                :position="selection.position"
                :isCreator="isCreator"
                @bet-placed="dispatchBet" />
        </div>
        <div v-show="expanded && isCreator" class="card-footer | flow-1">
            <div class="footer-buttons">
                <div v-show="this.data.status == 'open'" class="footer-button" @click="dispatchUpdateMarketStatus('suspended')">
                    <h3 class="fw-semi-bold">Suspend</h3>
                </div>
                <div v-show="this.data.status == 'suspended'" class="footer-button" @click="dispatchUpdateMarketStatus('open')">
                    <h3 class="fw-semi-bold">Open</h3>
                </div>
                <div v-show="this.data.status == 'suspended'" class="footer-button" @click="toggleSettlementActive">
                    <h3 class="fw-semi-bold">Settle</h3>
                </div>
            </div>
            <div v-show="(this.data.status == 'suspended') && settlementActive" class="settle-panel">
                <select v-model="winningSelection" class="winner-select">
                    <option class="placeholder" value=0 disabled selected hidden>Select Winner</option>
                    <option v-for="selection in sortedSelections" :value="selection">
                        {{ selection.name }}
                    </option>
                </select>
                <div class="confirm-button" @click="toggleConfirmSettlement">
                    <h3 class="fw-semi-bold">Confirm</h3>
                </div>
            </div>
            <AreYouSure v-show="this.confirmSettlement" 
                        :message="confirmSettlementMessage"
                        @response="handleConfirmSettlement" />
        </div>
        <div v-show="isError" class="error-display">
            <div class="error-message">
                <h3 class="fs-small"><span class="fw-bold">ERROR:</span> {{ error }}</h3>
            </div>
            <div class="error-dismiss" @click="dismissError()">
                <h3 class="fw-semi-bold">OK</h3>
            </div>
        </div>
    </div>
</template>

<script>
import { api } from '@/api';
import { mapState } from 'vuex';
import AreYouSure from './AreYouSure.vue';
import Selection from './Selection.vue';

export default {
    name: "MarketCard",
    components: { Selection, AreYouSure },
    props: {
        data: Object,
        isCreator: Boolean,
    },
    data() {
        return {
            expanded: true,
            settlementActive: false,
            winningSelectionId: 0,
            winningSelection: null,
            confirmSettlement: false,
            error: ""
        }
    },
    computed: {
        ...mapState(['token']),
        sortedSelections() {
            // Want this to return a sorted COPY of the DISPLAY selections.
            return [...this.data.displaySelections].sort((a, b) => a.price - b.price)
        },
        isError() {
            return this.error != ""
        },
        confirmSettlementMessage() {
            if ( this.winningSelection == null ) {
                // This is not valid... but need to let the backend handle this
                // so present a message that seems fine...
                return 'Are you sure?'
            } else {
                return 'Are you sure you want to settle "' + this.data.name + '" with "' + this.winningSelection.name + '" as the winning selection?'
            }
        }
    },
    methods: {
        toggleExpanded() {
            this.expanded = !this.expanded
        },
        toggleSettlementActive() {
            this.settlementActive = !this.settlementActive
        },
        toggleConfirmSettlement() {
            this.confirmSettlement = !this.confirmSettlement
        },
        async handleConfirmSettlement(value) {
            this.toggleConfirmSettlement()
            if (value == 1) {
                await this.dispatchSettlement()
            }
        },
        async dispatchBet(selectionId, price, stake) {
            const response = await api.createBet(
                this.token, selectionId, price, stake,
                this.data.id, this.data.eventId
            )
            if (response.success) {
                this.$emit('bet-placed')
            } else {
                this.error = response.payload.message
            }
        },
        async dispatchUpdateMarketStatus(newStatus) {
            await api.updateMarketStatus(this.token, this.data.id, newStatus)
            this.$emit('market-status-change')
        },
        async dispatchSettlement() {
            // Check if a selection has actually been selected.
            if (this.winningSelection == null) {
                this.error = "Must select a winning selection!"
            } else {
                await api.settlement(this.token, this.data.id, this.winningSelection.id)
                this.$emit('market-settled')
            }
        },
        dismissError() {
            this.error = ""
        }
    }
}

</script>

<style scoped>

.card {
    border-radius: 10px;
    overflow: hidden;
}

.card-header {
    display: flex;
    justify-content: space-between;
    gap: 0.25rem;
}

.card-title {
    background-color: var(--clr-primary-light);
    padding: 0.5rem;
    flex: 1;
}

.card-title-creator {
    background-color: var(--clr-secondary-light)
}

.card-toggle {
    background-color: var(--clr-primary-dark);
    padding: 0.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.card-toggle-creator {
    background-color: var(--clr-secondary-dark);
}

.card-toggle:hover {
    background-color: var(--clr-primary)
}

.card-toggle-creator:hover {
    background-color: var(--clr-secondary)
}

.card-toggle:active {
    background-color: var(--clr-primary-light)
}

.card-toggle-creator:active {
    background-color: var(--clr-secondary-light)
}

.footer-buttons {
    display: flex;
    gap: 0.25rem;
}

.footer-button {
    flex: 1;
    background-color: var(--clr-secondary-light);
    text-align: center;
    padding: 5px;
    cursor: pointer;
}

.footer-button:hover {
    background-color: var(--clr-secondary)
}

.footer-button:active {
    background-color: white;
}

.settle-panel {
    padding: 0.5rem;
    display: flex;
    justify-content: space-around;
    background-color: var(--clr-secondary-dark)
}

.settle-panel > * {
    flex: 1;
    text-align: center;
}

select.winner-select {
    background-color: var(--clr-secondary-extralight);
    padding: 5px;
    border: none;
    border-radius: 10px 0 0 10px;
    color: black;
}

option.placeholder {
    color: var(--clr-neutral-dark)
}

.confirm-button {
    background-color: var(--clr-secondary-extradark);
    color: white;
    padding: 5px;
    border-radius: 0 10px 10px 0;
    cursor: pointer;
}

.confirm-button:hover {
    background-color: var(--clr-secondary);
}

.confirm-button:active {
    background-color: var(--clr-secondary-light);
}

.error-display {
    display: flex;
    justify-content: space-between;
    gap: 0.25rem;
}

.error-display > * {
    background-color: var(--clr-error);
    color: white;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

}

.error-message {
    flex: 1;
}

.error-dismiss {
    width: 60px;
    cursor: pointer;
}

.error {
    background-color: var(--clr-error);
}


</style>