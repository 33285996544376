<template>
    <div class="card">
        <div class="header">
            <div class="selection-name" :class="{'selection-name-lay': !isBack}">
                <h3 class="fs-subheader fw-semi-bold">{{ bet.selectionName }}</h3>
            </div>
            <div class="status" :class="displayStatus">
                <h3>{{ displayStatus.toUpperCase() }}</h3>
            </div>
        </div>
        <div class="middle">
            <div class="details | fs-small">
                <h3>{{ bet.marketName }}</h3>
                <h3>{{ bet.eventName }}</h3>
                <h3>{{ bet.timestampStruck }}</h3>
            </div>
            <div class="price">
                <h3 class="fs-subheader fw-semi-bold">{{ bet.price }}</h3>
            </div>
        </div>
        <div class="footer">
            <div class="stake">
                <h3>Stake: <span class="fw-semi-bold">{{ bet.stake.toFixed(2) }}</span></h3>
            </div>
            <div class="return">
                <h3>Return: <span class="fw-semi-bold">{{ betReturn.toFixed(2) }}</span></h3>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'BetCard',
    props: {
        bet: Object,
        isBack: Boolean
    },
    computed: {
        displayStatus() {
            if (this.bet.status == 'open') {
                if (this.isBack == true) {
                    return 'open'
                } else {
                    return 'laid'
                }
            } else {
                if (this.bet.outcome == 1) {
                    return 'won'
                } else {
                    return 'lost'
                }
            }
        },
        betReturn() {
            if (this.bet.status == 'open') {
                return this.bet.stake * this.bet.price
            } else {
                return this.bet.stake * this.bet.price * this.bet.outcome
            }
        }
    }
}

</script>

<style scoped>

.card {
    background-color: var(--clr-neutral-extralight);
    border-radius: 10px;
    overflow: hidden;
}

.header {
    display: flex;
    justify-content: space-between;
}

.selection-name {
    padding: 0.25rem 0.5rem;
    flex: 1;
    background-color: var(--clr-neutral-light);
    border-left: 10px solid var(--clr-primary);
}

.selection-name-lay {
    border-left: 10px solid var(--clr-secondary);
}

.price {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 1rem;
    width: 90px;
    background-color: var(--clr-neutral-light);
}

.middle {
    display: flex;
    justify-content: space-between;
}

.details {
    padding: 0.25rem 0.5rem;
    opacity: 0.8;
}

.status {
    padding: 0.25rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
}

.open {
    background-color: var(--clr-primary);
}

.laid {
    background-color: var(--clr-secondary);
}

.footer {
    display: flex;
    justify-content: space-between;
    background-color: var(--clr-neutral-extralight);
    border-top: 2px solid var(--clr-neutral-light)
}

.footer > * {
    padding: 0.25rem 0.5rem;
    flex: 1;
    text-align: center;
}

.footer > * + * {
    border-left: 2px solid var(--clr-neutral-light)
}

@media (min-width: 50em) {
    .card {
        width: max(60%, 30em);
    }
}


</style>