<template>
    <div class="tab | flow-3">
        <div class="toggles">
            <div class="option-toggle">
                <div :class="{ 'bets-toggle-back': this.toggleBack }" @click="() => this.toggleBack = true">
                    <h3>BACK</h3>
                </div>
                <div :class="{ 'bets-toggle-lay': !this.toggleBack }" @click="() => this.toggleBack = false">
                    <h3>LAY</h3>
                </div>
            </div>
            <div class="option-toggle">
                <div :class="{ 'status-toggle-open': this.toggleOpen }" @click="() => this.toggleOpen = true">
                    <h3>OPEN</h3>
                </div>
                <div :class="{ 'status-toggle-settled': !this.toggleOpen }" @click="() => this.toggleOpen = false">
                    <h3>SETTLED</h3>
                </div>
            </div>
        </div>
        <div class="flow-3">
            <BetCard v-for="(bet, index) in filteredBets"
                :index="index" 
                :bet="bet"
                :isBack="this.toggleBack" />
            <div v-show="(filteredBets.length == 0)" class="placeholder-card">
                <h3>No Bets Found</h3>
            </div>    
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import BetCard from './BetCard.vue';

export default {
    name: 'EventBetsTab',
    props: [ 'bets' ],
    data() {
        return {
            toggleBack: true,
            toggleOpen: true
        }
    },
    computed: {
        ...mapState(["user"]),
        filteredBets() {
            return (
                this.bets
                .filter(this.backLayFilter)
                .filter(this.statusFilter)
                .sort((a, b) => Date.parse(b.timestampStruck) - Date.parse(a.timestampStruck))
            );
        },
    },
    methods: {
        backLayFilter(bet) {
            if (this.toggleBack) {
                return bet.userId == this.user.id
            } else {
                return bet.userId != this.user.id
            }
        },
        statusFilter(bet) {
            if (this.toggleOpen) {
                return bet.status == 'open'
            } else {
                return bet.status != 'open'
            }
        },
    },
    components: { BetCard }
}

</script>

<style scoped>

.toggles {
    display: flex;
    justify-content: left;
    gap: 1rem;
}

.option-toggle {
    width: max-content;
    background-color: var(--clr-neutral-extralight);
    border-radius: 10px;
    overflow: hidden;
}

.option-toggle > div {
    display: inline-block;
    text-align: center;
    cursor: pointer;
}

.option-toggle > div > h3 {
    padding: 0.5rem;
}

.bets-toggle-back {
    background-color: var(--clr-primary)
}

.bets-toggle-lay {
    background-color: var(--clr-secondary)
}

.status-toggle-open,
.status-toggle-settled {
    background-color: var(--clr-neutral-dark);
    color: white;
}


.placeholder-card {
    background-color: var(--clr-neutral-extralight);
    border-radius: 10px;
    padding: 1rem;
}


@media (min-width: 50em) {
    .placeholder-card {
        width: max(60%, 30em);
    }
}

</style>