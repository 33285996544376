import { createStore } from 'vuex';

import router from '../router'
import { api } from '../api'


export default createStore({
  state() {
    return {
      token: '',
      isLoggedIn: false,
      user: null,
    }
  },
  getters: {
  },
  mutations: {
    setToken(state, accessToken) {
      state.token = accessToken
    },
    setUser(state, user) {
      state.user = user
    },
    setLoggedIn(state, value) {
      state.isLoggedIn = value
    }
  },
  actions: {
    async actionLogin(context, payload) {
        const data = await api.actionLogin(payload)

        if (data.success) {
          context.commit('setToken', data.payload.accessToken)
          context.commit('setUser', data.payload.user)
          context.commit('setLoggedIn', true)

          // Having logged in, we redirect the user to the home page.
          router.push('/')
        } else {
          return data.payload.message
        }
    }
  },
  modules: {
  }
})
