import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index'

import Admin from '../views/Admin.vue'
import Event from '../views/Event.vue'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/events/:id',
    name: 'Event',
    component: Event
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Put something in place that will redirect any user that's not logged in
// to the login page.
router.beforeEach( (to, from) => {
  if (!store.state.isLoggedIn && to.name !== 'Login') {
    return { name: 'Login' }
  }
})

export default router
