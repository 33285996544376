<template>
    <div class="header">
        <img class="icon-large" src="../assets/logo_pink.png">
        <div class="links">
            <router-link to="/admin" v-show="user.isSuperuser">Admin</router-link>
            <router-link to="/login">Logout</router-link>
        </div>
    </div>
    <div class="join-event">
        <input type="text" v-model="joinCode" placeholder="Event Code" />
        <div class="join-event-button" @click="dispatchJoin()">
            <h5 class="fs-subheader fw-bold">Join</h5>
        </div>
    </div>
    <div v-show="isError" class="error-display">
        <div class="error-message">
            <h3 class="fs-small"><span class="fw-bold">ERROR:</span> {{ error }}</h3>
        </div>
        <div class="error-dismiss" @click="dismissError()">
            <h3 class="fw-semi-bold">OK</h3>
        </div>
    </div>
    <div class="container | flow-5">
        <MembershipCard v-for="(membership, index) in memberships"
            :key="membership.eventId"
            :index="index"
            :data="membership" />
        <CreateEventCard @new-event-created="refreshMemberships()"/>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import { api } from '@/api'
import CreateEventCard from '@/components/CreateEventCard.vue';
import MembershipCard from '@/components/MembershipCard.vue'

export default {
    name: "Home",
    data() {
        return {
            memberships: [],
            joinCode: "",
            error: ""
        };
    },
    async created() {
        await this.refreshMemberships();
    },
    computed: {
        ...mapState(["token", "user"]),
        isError() {
            return this.error != "";
        }
    },
    methods: {
        async refreshMemberships() {
            this.memberships = await api.getMembership(this.token, null, this.user.id);
        },
        async dispatchJoin() {
            const response = await api.joinEvent(this.token, this.joinCode);

            if (response.success) {
                this.refreshMemberships();
            } else {
                this.error = response.payload.message
            }

        },
        dismissError() {
            this.error = ""
        }
    },
    components: { MembershipCard, CreateEventCard }
}

</script>

<style scoped>
.header {
    display: flex;
    background-color: var(--clr-neutral-extradark);
    padding: 1rem;
    justify-content: space-between;
    align-items: center;
}

.links > a {
    color: white;
    margin-left: 1rem;
}


.join-event {
    display: flex;
    justify-content: left;
    gap: 0.5rem;
    background-color: var(--clr-neutral);
    padding: 0.5rem;
    flex-wrap: wrap;
}

.join-code-entry {
    flex: 1;
    padding: 0.5rem;
}

input {
    border: none;
    padding: 0.25rem 0.5rem;
    border-radius: 10px;
}

.join-event-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--clr-neutral-dark);
    color: white;
    width: 100px;
    padding: 0.5rem;
    cursor: pointer;
    border-radius: 10px;
}

.join-event-button:hover {
    background-color: var(--clr-neutral-extradark);
}

.join-event-button:active {
    background-color: var(--clr-neutral-light);
}

.error-display {
    display: flex;
    justify-content: space-between;
    gap: 0.25rem;
}

.error-display > * {
    background-color: var(--clr-error);
    color: white;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.error-message {
    flex: 1;
}

.error-dismiss {
    width: 60px;
    cursor: pointer;
}


.error {
    background-color: var(--clr-error);
}

.container {
    margin-top: 1.5rem;
}

@media (min-width: 50em) {
    .links > a {
        margin-left: 3rem;
    }
}

</style>