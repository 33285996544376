<template>
    <div class="leaderboard | flow-mini">
        <div class="leaderboard-row header-row">
            <div class="rank header-rank"></div>
            <h3 class="name | fw-semi-bold">Name</h3>
            <h3 class="bet-count-header | fw-semi-bold">Live</h3>
            <h3 class="balance | fw-semi-bold">Balance</h3>
        </div>
        <div v-for="(row, index) in sortedLeaderboard" class="leaderboard-row">
            <div class="rank">
                <h3 class="fs-subheader fw-semi-bold">{{ index + 1 }}</h3>
            </div>
            <div class="name">
                <h3 class="fs-subheader">{{ row.userName }}</h3>        
            </div>
            <div class="bet-count open-back-bets">
                <h3>{{ row.openBackBets }}</h3>
            </div>
            <div class="bet-count open-lay-bets">
                <h3>{{ row.openLayBets }}</h3>
            </div>
            <div class="balance">
                <h3 class="fs-subheader fw-semi-bold">{{ Math.round(row.balance*100) / 100 }}</h3>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'EventLeaderboardTab',
    props: [ 'leaderboard' ],
    computed: {
        sortedLeaderboard() {
            return this.leaderboard.sort((a, b) => b.balance - a.balance)
        }
    }
}

</script>

<style scoped>

.leaderboard {
    border-radius: 10px;
    overflow: hidden;
}

.leaderboard-row {
    display: flex;
    justify-content: space-between;
}

.rank {
    width: 30px;
    background-color: var(--clr-neutral-dark);
    color: white;
    text-align: center;
    padding: 0.25rem;
}

.name {
    background-color: var(--clr-neutral-extralight);
    flex: 1;
    padding: 0.25rem 0.5rem;
}

.balance {
    width: 75px;
    padding: 0.25rem;
    background-color: var(--clr-neutral-light);
    text-align: center;
}

.bet-count {
    width: 30px;
    padding: 0.25rem;
    text-align: center;
}

.bet-count-header {
    width: 60px;
    padding: 0.25rem;
    text-align: center;
}


.open-back-bets {
    background-color: var(--clr-primary-light);
}

.open-lay-bets {
    background-color: var(--clr-secondary-light);
}

.header-row > h3 {
    background-color: var(--clr-neutral-dark);
    color: white;
}

.header-row > * + * {
    border-left: 1px solid white;
}


@media (min-width: 50em) {

    .leaderboard {
        width: max(60%, 30em);
        margin-top: 3rem;
    }

    .balance {
        width: 125px;
    }

    .bet-count {
        width: 60px;
    }

    .bet-count-header {
        width: 120px;
    }

}

</style>