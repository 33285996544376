<template>
    <div class="card | flow-1">
        <div class="card-header">
            <div class="card-title" :class="{'error': (error.loc == 'name') && expanded}">
                <input v-show="expanded"
                    class="market-name-input"
                    name="new-market-name"
                    v-model="name"
                    placeholder="Market Name" />    
            </div>
            <div class="card-toggle" @click="toggleExpanded()">
                <img v-if="expanded"
                    class="icon icon-regular"
                    src="../assets/minus-light.png"
                    alt="Contract">
                <img v-else class="icon icon-regular" 
                    src="../assets/plus-light.png" 
                    alt="Plus">
            </div>
        </div>
        <div v-show="expanded" class="card-body | flow-1">
            <CreateSelection v-for="(selection, index) in selections"
                :index="index"
                :selection="selection"
                :showDeleteButton="showDeleteButton"
                :isError="(isError) && (error.loc == 'selections')"
                @delete-selection="deleteSelection(selection)" />
        </div>
        <div v-show="expanded" class="card-footer">
            <div class="market-button" @click="addSelection()">
                <span class="fw-semi-bold">Add Selection</span>
            </div>
            <div class="market-button" @click="dispatchCreateMarket()">
                <span class="fw-semi-bold">Create Market</span>
            </div>
        </div>
        <div v-show="isError" class="error-display">
            <div class="error-message">
                <h3 class="fs-small"><span class="fw-bold">ERROR:</span> {{ error.message }}</h3>
            </div>
            <div class="error-dismiss" @click="dismissError()">
                <h3 class="fw-semi-bold">OK</h3>
            </div>
        </div>
    </div>  
</template>

<script>
import { mapState } from 'vuex';
import { api } from '@/api.js';
import CreateSelection from './CreateSelection.vue'

export default {
    name: "CreateMarketCard",
    data() {
        return {
            expanded: false,
            name: "",
            selections: [
                {name: "", trueProbability: ""},
                {name: "", trueProbability: ""}
            ],
            error: "" 
        };
    },
    props: {
        eventId: Number
    },
    computed: {
        ...mapState(['token']),
        showDeleteButton() {
            return this.selections.length > 2
        },
        isError() {
            return this.error != "" 
        }
    },
    methods: {
        toggleExpanded() {
            if (this.expanded) {
                // Reset the card.
                this.name = "";
                this.selections = [
                    {name: "", trueProbability: ""},
                    {name: "", trueProbability: ""}
                ];
                this.error = ""
            }

            this.expanded = !this.expanded;
        },
        addSelection() {
            this.selections = [
                ...this.selections,
                {name: "", trueProbability: ""}
            ]
        },
        deleteSelection(selection) {
            const ix = this.selections.indexOf(selection)
            this.selections.splice(ix, 1)
        },
        async dispatchCreateMarket() {
            const response = await api.createMarket(
                this.token, 
                this.eventId, 
                this.name,
                this.selections
            )

            if (response.success == true) {
                // In case a success directly follows a failure (likely)
                this.dismissError()
                this.toggleExpanded()

                this.$emit('market-status-change')
            } else {
                this.error = response.payload
            }
        },
        dismissError() {
            this.error = "" 
        }
    },
    components: { CreateSelection },
    emits: [ 'market-status-change' ]
}

</script>

<style scoped>

.card {
    border-radius: 10px;
    overflow: hidden;
}

.card-header {
    display: flex;
    justify-content: space-between;
    gap: 0.25rem;
}

.card-title {
    background-color: var(--clr-neutral-light);
    width: 100%;
    padding: 0.5rem;
}

.market-name-input {
    border: none;
    width: 100%;
    padding: 0.25rem;
    border-radius: 10px;
}

.card-toggle {
    background-color: var(--clr-neutral-dark);
    display: flex;
    align-items: center;
    padding: 0.5rem;
    cursor: pointer;
}

img {
    margin: none
}

.card-footer {
    display: flex;
    justify-content: space-around;
    gap: 0.25rem;
}

.market-button {
    background-color: var(--clr-neutral-light);
    width: 100%;
    padding: 0.25rem;
    text-align: center;
    cursor: pointer;
}

.card-toggle:hover {
    background-color: var(--clr-neutral);
}

.card-toggle:active {
    background-color: var(--clr-neutral-light);
}

.market-button:hover {
    background-color: var(--clr-neutral);
}

.market-button:active {
    background-color: var(--clr-neutral-dark);
}

.error-display {
    display: flex;
    justify-content: space-between;
    gap: 0.25rem;
}

.error-display > * {
    background-color: var(--clr-error);
    color: white;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

}

.error-message {
    flex: 1;
}

.error-dismiss {
    width: 60px;
    cursor: pointer;
}


.error {
    background-color: var(--clr-error);
}

</style>
