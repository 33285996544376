<template>
    <div class="overlay">
        <div class="box">
            <p class="message">{{ message }}</p>
            <div class="buttons">
                <div class="button" @click="this.$emit('response', 1)">
                    <h3>Yes</h3>
                </div>
                <div class="button" @click="this.$emit('response', 0)">
                    <h3>No</h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'AreYouSure',
    props: [ 'message' ]
}

</script>

<style scoped>

.overlay {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background-color: rgb(0, 0, 0, 0.5);    
}

.box {
    position: absolute;
    z-index: 10001;
    top: 40%;
    left: 15%;
    width: 70%;
    background-color: var(--clr-neutral-extralight);
    border: 6px solid var(--clr-neutral);
    border-radius: 10px;
    overflow: hidden;
}

p.message {
    padding: 1rem;
}

.buttons {
    display: flex;
    justify-content: space-evenly;
    gap: 0.25rem;
}

.button {
    background-color: var(--clr-neutral-light);
    width: 100%;
    padding: 0.5rem;
    text-align: center;
    cursor: pointer;
}


</style>