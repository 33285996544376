<template>
    <div class="container | flow-5">
        <table class="users-table">
            <tr class="header-row">
                <th>ID</th>
                <th>NAME</th>
            </tr>
            <tr v-for="user in users" class="user-row">
                <td>{{ user.id }}</td>
                <td>{{ user.name }}</td> 
            </tr>
        </table>
        <div class="new-user">
            <h3>Name</h3>
            <input type="text" v-model="name" />
            <h3>Password</h3>
            <input type="text" v-model="password" />
            <button @click="dispatchCreateUser">Create User</button>
        </div>
        <div v-show="error != ''" class="error-message">{{ error }}</div>
    </div>
</template>

<script>
import { api } from '@/api';
import { mapState } from 'vuex';

export default {
    name: 'Admin',
    data() {
        return {
            users: [],
            name: "",
            password: "",
            error: ""
        }
    },
    computed: {
        ...mapState(['token'])
    },
    async created() {
        await this.refreshUsers()
    },
    methods: {
        async refreshUsers() {
            this.users = await api.getUsers(this.token)
            this.name = ""
            this.password = ""
        },
        async dispatchCreateUser() {
            // Reset the error message if there is one.
            this.error = "";

            const response = await api.createUser(this.token, this.name, this.password)

            if (response.success) {
                await this.refreshUsers()
            } else {
                this.error = response.payload.message
            }
        }
    }
}

</script>

<style scoped>

.container > * {
    margin-top: 5rem; 
}

table {
    width: 100%;
}

tr.header-row {
    background-color: var(--clr-neutral-light);
}

table > tr + tr {
    background-color: var(--clr-neutral-extralight);
}

td {
    padding-left: 1rem;
}

.new-user {
    display: grid;
    grid-template-columns: 2;
    grid-template-rows: 2;
    gap: 1rem;
    width: max-content;
}

.new-user > h3 {
    text-align: right;
}

.new-user > input, button {
    grid-column: 2;
}

.error-message {
    background-color: red;
    color: white;
}

</style>