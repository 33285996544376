<template>
    <footer>
        <div class="footer">
            <a href="https://www.flaticon.com/free-icons/read-more" 
               title="read more icons"
               class="footer-link | fs-small">
               Read more icons created by Stockes Design - Flaticon
            </a>
        </div>
    </footer>
</template>

<script>

export default {
    name: 'Footer'
}

</script>

<style scoped>

.footer {
    width: 100%;
    height: 50px;
    padding: 10px;
    margin-top: 30px;
    background-color: black;
}

.footer-link {
    color: white;
}

</style>