<template>
    <div class="selection" :class="{'selection-creator': isCreator}">
        <div class="selection-header" :class="{'selection-header-creator': isCreator}">
            <div class="selection-name">
                <h1 class="name">{{ name }}</h1>
                <div v-show="(position != 0)"
                    class="position | fs-extrasmall"
                    :class="{'position-negative': (position < 0)}">
                    {{ position.toFixed(2) }}
                </div>
            </div>
            <h3 v-if="isCreator" class="price-creator | fw-semi-bold">
                {{ price }}
            </h3>
            <h3 v-else class="price | fw-semi-bold" @click="toggleExpanded()">
                {{ price }}
            </h3>
        </div>
        <div v-show="expanded" class="selection-footer">
            <div class="stake-input">
                <input type="number"
                    placeholder="Stake"
                    v-model="stake">
            </div>
            <div class="place-button" @click="toggleConfirmPlaceBet">
                <h3 class="fw-bold">Place</h3>
            </div>
            <AreYouSure v-show="confirmPlaceBet"
                        :message="confirmPlaceBetMessage"
                        @response="handleConfirmPlaceBet" />
        </div>
    </div>
</template>

<script>
import AreYouSure from './AreYouSure.vue';

export default {
    name: "Selection",
    data() {
        return {
            expanded: false,
            stake: 0,
            confirmPlaceBet: false
        };
    },
    props: {
        selectionId: Number,
        name: String,
        price: Number,
        position: Number,
        isCreator: Boolean
    },
    computed: {
        confirmPlaceBetMessage() {
            return "Stake " + this.stake.toFixed(2) + " on \"" + this.name + "\" at " + this.price.toFixed(2) + "?";
        }
    },
    methods: {
        toggleExpanded() {
            if (this.expanded) {
                this.stake = 0;
            }
            this.expanded = !this.expanded;
        },
        toggleConfirmPlaceBet() {
            this.confirmPlaceBet = !this.confirmPlaceBet;
        },
        handleConfirmPlaceBet(value) {
            this.toggleConfirmPlaceBet();
            if (value == 1) {
                this.dispatchPlaceBet();
            }
        },
        dispatchPlaceBet() {
            this.$emit("bet-placed", this.selectionId, this.price, this.stake);
            this.toggleExpanded();
        }
    },
    components: { AreYouSure }
}

</script>

<style scoped>

.selection {
    background-color: var(--clr-primary-light);
}

.selection-header {
    display: flex;
    justify-content: space-between;
    height: 100%;
    background-color: var(--clr-primary-extralight);
}

.selection-header-creator {
    background-color: var(--clr-secondary-extralight);
}

.selection-footer {
    display: flex;
    justify-content: space-between;
    background-color: var(--clr-primary-light);
}
.selection-name {
    padding: 0.25rem 0.5rem;
    flex: 1;
}

.position {
    opacity: 0.7;
    color: darkgreen
}

.position-negative {
    color: darkred
}

.price {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    background-color: var(--clr-primary);
    padding: 5px;
    cursor: pointer;
}

.price:hover {
    background-color: var(--clr-primary-light);
}

.price:active {
    background-color: var(--clr-primary-light);
}

.price-creator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    padding: 5px;
    background-color: var(--clr-secondary);
}

.stake-input {
    padding: 0.5rem;
    width: 50%;
}

input {
    border: none;
    border-radius: 10px;
    padding: 0.5rem;
    width: 100%;
}

.place-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    color: white;
    background-color: var(--clr-primary-dark);
    width: 50%;
    cursor: pointer;
}

.place-button:hover {
    background-color: var(--clr-primary);
}

.place-button:active {
    background-color: var(--clr-primary)
}

</style>