<template>
  <main>
    <router-view/>
  </main>
  <Footer />
</template>

<script>
import Footer from './components/Footer.vue';
  
export default {
    name: "App",
    components: { Footer }
}

</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500&display=swap');

:root {
  --clr-primary-extralight: hsl(216, 80%, 95%);
  --clr-primary-light: hsl(216, 80%, 90%);
  --clr-primary: #a3c4f5;
  --clr-primary-dark: hsl(216, 80%, 70%);
  --clr-primary-extradark: hsl(216, 80%, 50%);

  --clr-secondary-extralight: hsl(308, 50%, 95%);
  --clr-secondary-light: hsl(308, 50%, 90%);
  --clr-secondary: #e6b3df;
  --clr-secondary-dark: hsl(309, 50%, 70%);
  --clr-secondary-extradark: hsl(309, 50%, 50%);

  --clr-neutral-extralight: hsl(0, 0%, 95%);
  --clr-neutral-light: hsl(0, 0%, 90%);
  --clr-neutral: hsl(0, 0%, 70%);
  --clr-neutral-dark: hsl(0, 0%, 50%);
  --clr-neutral-extradark: hsl(0, 0%, 30%);

  --clr-error: hsl(0, 90%, 70%);

  --ff-header: 'Bebas Neue', cursive;
  --ff-regular: 'Maven Pro', sans-serif;  

}

/* CSS Reset */

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  font: inherit;
}

ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}


html,
body {
    height: 100%
}

/* Set core body defaults */
body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
   scroll-behavior: auto;
  }
  
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

body {
  font-family: var(--ff-regular);
}

main {
  /* 80px specifically for the footer. */
  min-height: calc(100vh - 80px);
}

/* Font Sizing */

body {
  font-size: 1rem;
}

.fs-header {
  font-size: 2rem;
}

.fs-subheader {
  font-size: 1.125rem;
}

.fs-small {
  font-size: 0.875rem;
}

.fs-extrasmall {
  font-size: 0.75rem;
}

.fs-button {
  font-size: 1.25rem; 
}

@media (min-width: 50em) {
  body {
    font-size: 1.25rem;
  }

  .fs-header {
    font-size: 2rem;
  }

  .fs-subheader {
    font-size: 1.25rem;
  }

  .fs-small {
    font-size: 1rem;
  }
}

/* Font Weight */

body {
  font-weight: 400;
}

.fw-semi-bold {
  font-weight: 500;
}

.fw-bold {
  font-weight: 700;
}

/* Font Family Special */

.ff-header {
  font-family: var(--ff-header);
}

/* Flow */

.flow-mini > * + * {
  margin-top: 1px;
}

.flow-1 > * + * {
  margin-top: 0.25rem;
}

.flow-3 > * + * {
  margin-top: 1rem;
}

.flow-5 > * + * {
  margin-top: 1.5rem;
}

/* Icon */

img.icon {
  max-width: none;
}

img.icon-small {
  height: 1rem;
  width: 1rem;
}

img.icon-regular {
  height: 1.5rem;
  width: 1.5rem;
}

img.icon-large {
  height: 2rem;
  width: 2rem;
}

@media (min-width: 50em) {
  img.icon-small {
    height: 1.5rem;
    width: 1.5rem;
  }

  img.icon-regular {
    height: 2rem;
    width: 2rem;
  }

  img.icon-large {
    height: 3rem;
    width: 3rem;
  }
}

/* Component Classes */

.container {
  width: min(90%, 100% - 2rem);
  margin-inline: auto;
}

.icon-button,
.icon-button-large {
  cursor: pointer;
  padding: 10px;
  border-radius: 1000px;
  width: fit-content;
}

.icon-button-large {
  padding: 20px;
}

</style>
