<template>
    <div class="header">
        <router-link class="logo-block" to="/">
            <div class="logo-block">
                <img class="icon-large" src="../assets/logo_pink.png">
            </div>
        </router-link>
        <div class="title-block">
            <h1 class="fs-header">{{ eventName }}</h1>
        </div>
        <div class="balance-block">
            <h5 class="fs-subheader">BALANCE: <span class="fw-bold">{{ balance.toFixed(2) }}</span></h5>
        </div>
    </div>  
    <div class="nav">
        <div class="tab" :class="{ 'active-tab': marketsActive }" @click="setMarketsActive">MARKETS</div>
        <div class="tab" :class="{ 'active-tab': betsActive }" @click="setBetsActive">BETS</div>
        <div class="tab" :class="{ 'active-tab': leaderboardActive }" @click="setLeaderboardActive">LEADERBOARD</div>
    </div>
    <div class="container">
        <EventMarketsTab v-show="marketsActive"
            :markets="this.markets"
            :eventId="this.eventId"
            @bet-placed="betPlaced()" 
            @market-status-change="refreshMarkets()"
            @market-settled="marketSettled()" />
        <EventBetsTab v-show="betsActive" :bets="this.bets" />
        <EventLeaderboardTab v-show="leaderboardActive" :leaderboard="this.leaderboard"/>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { api } from '../api.js'
import EventMarketsTab from '@/components/EventMarketsTab.vue';
import EventBetsTab from '@/components/EventBetsTab.vue';
import EventLeaderboardTab from '@/components/EventLeaderboardTab.vue';

export default {
    name: "Event",
    data() {
        return {
            balance: 0,
            eventId: 0,
            eventName: "",
            markets: [],
            bets: [],
            leaderboard: [],
            marketsActive: true,
            betsActive: false,
            leaderboardActive: false,
        };
    },
    computed: {
        ...mapState(["token", "user"]),
    },
    async created() {
        await this.refreshEvent();
        await this.refreshMarkets();
    },
    methods: {
        async refreshEvent() {
            const membership = await api.getMembership(this.token, this.$route.params.id, this.user.id);
            this.eventId = membership.event.id
            this.eventName = membership.event.name
            this.balance = membership.balance
        },
        async refreshMarkets() {
            this.markets = await api.getMarketsByEventId(this.token, this.eventId);
        },
        async refreshBets() {
            this.bets = await api.getBets(this.token, this.eventId)
        },
        async refreshLeaderboard() {
            this.leaderboard = await api.getLeaderboard(this.token, this.eventId)
        },
        async betPlaced() {
            await this.refreshEvent()
            await this.refreshMarkets()
        },
        async marketSettled() {
            await this.refreshEvent()
            await this.refreshMarkets()
        },
        async setMarketsActive() {
            await this.refreshMarkets()
            this.marketsActive = true;
            this.betsActive = false;
            this.leaderboardActive = false;
        },
        async setBetsActive() {
            await this.refreshBets()
            this.marketsActive = false;
            this.betsActive = true;
            this.leaderboardActive = false;
        },
        async setLeaderboardActive() {
            await this.refreshLeaderboard()
            this.marketsActive = false;
            this.betsActive = false;
            this.leaderboardActive = true;
        },

    },
    components: { EventMarketsTab, EventBetsTab, EventLeaderboardTab }
}

</script>

<style scoped>

.header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.header > * {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.5rem;
}

.logo-block {
    padding: 0.5rem 0.5rem 0rem;
    background-color: var(--clr-neutral-extradark);
}

.title-block {
    background-color: var(--clr-neutral-extradark);
    color: white;
    text-align: center;
    width: 100%;
}

.balance-block {
    background-color: var(--clr-neutral-dark);
    color: white;
    padding: 0.25rem;
}

.nav {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 1.5rem;
}

.nav > * {
    background-color: var(--clr-neutral);
    color: white;
    text-decoration: none;
    flex: 1;
    text-align: center;
    cursor: pointer;
    padding: 0.25rem 0;
}

.nav > *:hover {
    background-color: var(--clr-neutral-light)
}

.nav > .active-tab {
    background-color: var(--clr-neutral-light);
    color: var(--clr-neutral-extradark)
}

@media (min-width: 50em) {
    .header > * {
        justify-content: left;
        padding: 1rem;
    }

    .title-block {
        width: max-content;
    }

    .logo-block, .balance-block {
        flex-grow: 0;
    }
}

</style>
