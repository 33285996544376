<template>
    <div class="card | flow-1">
        <div class="card-header">
            <div class="card-name" :class="{'error': isError}">
                <input v-show="expanded" 
                    type="text" 
                    placeholder="Event Name"
                    v-model="name">
            </div>
            <div class="card-toggle" @click="toggleExpanded()">
                <img v-if="expanded" class="icon icon-regular" src="../assets/minus-light.png" alt="Contract">
                <img v-else class="icon icon-regular" src="../assets/plus-light.png" alt="Expand">
            </div>
        </div>
        <div v-show="expanded" class="card-create" @click="dispatchCreateEvent()">
            <h3 class="fw-semi-bold">Create</h3>
        </div>
        <div v-show="isError" class="error-display">
            <div class="error-message">
                <h3 class="fs-small"><span class="fw-bold">ERROR:</span> {{ error }}</h3>
            </div>
            <div class="error-dismiss" @click="dismissError()">
                <h3 class="fw-semi-bold">OK</h3>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { api } from '@/api';

export default {
    name: "CreateEventCard",
    data() {
        return {
            expanded: false,
            name: "",
            error: ""
        };
    },
    computed: {
        ...mapState(['token']),
        isError() {
            return this.error != ""
        }
    },
    methods: {
        toggleExpanded() {
            if (this.expanded) {
                // Reset the card.
                this.name = ""
                this.error = ""
            }
            this.expanded = !this.expanded
        },
        async dispatchCreateEvent() {
            const response = await api.createEvent(this.token, this.name)
            
            if (response.success) {
                // Emit that a new event has been created.
                this.$emit('new-event-created')
                
                // Deactivate and reset the new event card.
                this.toggleExpanded()
            } else {
                // There's currently only one possible error here - an empty
                // event name.
                this.error = response.payload.message
            }
        },
        dismissError() {
            this.error = "";
        }
    }
}

</script>

<style scoped>

.card {
    border-radius: 10px;
    overflow: hidden;
}

.card-header {
    display: flex;
    justify-content: space-between;
    gap: 0.25rem;
}

.card-name {
    background-color: var(--clr-neutral-extradark);
    flex: 1;
    padding: 0.5rem;
}

.card-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--clr-neutral-dark);
    width: max-content;
    padding: 0.5rem;
    cursor: pointer;
}

.card-footer {
    display: flex;
    justify-content: space-between;
    gap: 0.25rem;
}

.card-create {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    background-color: var(--clr-neutral);
    cursor: pointer;
}

input {
    padding: 0.25rem;
    border: none;
    width: 100%;
    border-radius: 10px;
}

.card-toggle:active {
    background-color: var(--clr-neutral);
}

.card-create:active {
    background-color: white;
}

.error-display {
    display: flex;
    justify-content: space-between;
    gap: 0.25rem;
}

.error-display > * {
    background-color: var(--clr-error);
    color: white;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

}

.error-message {
    flex: 1;
}

.error-dismiss {
    width: 60px;
    cursor: pointer;
}


.error {
    background-color: var(--clr-error);
}


@media (min-width: 50em) {
    .card {
        width: max(40%, 30em);
    }
}


</style>