<template>
    <div class="header">
        <img class="icon-large" src="../assets/logo_pink.png">
    </div>
    <div class="login-container">
        <div class="login-form">
            <div class="form-inputs | flow-3">
                <input type="text"
                        name="username"
                        v-model="username"
                        placeholder="Username" />
                <input type="password"
                        name="password"
                        v-model="password"
                        placeholder="Password" />
            </div>
            <div class="login-button" @click="dispatchLogIn">
                <h3>Login</h3>
            </div>
            <div v-show="isError" class="error">
                <h3 class="fs-small"><span class="fw-bold">ERROR:</span> {{ error }}</h3>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: "Login",
    data() {
        return {
            username: "",
            password: "",
            error: ""
        };
    },
    computed: {
        isError() {
            return this.error != ""
        }
    },
    methods: {
        ...mapActions(["actionLogin"]),
        async dispatchLogIn() {
            // If the log in is successful then VueX handles the whole thing - 
            // we only get anything back here if it has failed.
            this.error = await this.actionLogin({username: this.username, password: this.password});
        }
    }
}

</script>

<style scoped>

.header {
    display: flex;
    background-color: var(--clr-neutral-extradark);
    padding: 0.5rem;
    justify-content: center;
}

.login-container {
    width: 100%;
    padding: 25vh 10vw 0;
}

.login-form {
    margin: auto;
    width: max(300px, 40%);
    border-radius: 20px;
    box-shadow: 0.5rem 0.5rem 3rem var(--clr-neutral);
    overflow: hidden;
}

.form-inputs {
    padding: 1rem;
    background-color: var(--clr-neutral-extralight);
}

.form-inputs > * {
    width: 100%;
    border: none;
    padding: 5px;
    border-radius: 10px;
}


.login-button {
    background-color: var(--clr-neutral-extradark);
    color: white;
    padding: 0.5rem 1rem;
    text-align: center;
    cursor: pointer;
}

.login-button:hover {
    background-color: var(--clr-neutral-dark);
}

.error-display {
    display: flex;
    justify-content: space-between;
    gap: 0.25rem;
    background-color: var(--clr-neutral-extralight);
}

.error {
    background-color: var(--clr-error);
    color: white;
    text-align: center;
    padding: 0.5rem;
}

</style>
