<template>
    <div class="selection" :class="{'error': isError}">
        <input type="text"
            placeholder="Selection Name"
            v-model="selection.name">
        <input type="text"
            class="probability-input"
            placeholder="Probability"
            v-model="selection.trueProbability">
        <div v-show="showDeleteButton" 
            class="delete-selection-button" 
            @click="$emit('delete-selection')">
            <img class="icon icon-small" src="../assets/minus-light.png" alt="Delete">
        </div>
    </div>
</template>

<script>
export default {
    name: 'CreateSelection',
    props: {
        selection: Object,
        showDeleteButton: Boolean,
        isError: Boolean
    },
    emits: ['delete-selection']
}

</script>

<style scoped>

.selection {
    display: flex;
    justify-content: space-between;
    background-color: var(--clr-neutral-light);
    padding: 0.5rem;
    gap: 0.5rem;
}

input {
    width: 100%;
    border: none;
    border-radius: 10px;
    padding: 0.25rem;
}

input.probability-input {
    width: 90px;
}

.delete-selection-button {
    background-color: var(--clr-neutral-dark);
    display: flex;
    align-items: center;
    border-radius: 1000px;
}

.error {
    background-color: var(--clr-error);
}

@media (min-width: 50em) {
    input.probability-input {
        width: 110px;
    }
}

</style>